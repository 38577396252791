import React, { useEffect, useState } from "react";
import "./Order.css";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../config";
import { List, Card, Image, Typography, Button, message, Spin } from "antd";
import authGuard from "../../guards/auth.guard";

const { Title, Text } = Typography;

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.user?.user?.id);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Auth token not available");
        }
        const response = await axios.get(`${config.BASE_URL}/myorders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        message.error("Error fetching orders: " + error.message);
      }
    };

    if (userId) {
      fetchOrders();
    }
  }, [userId]);

  const downloadInvoice = async (orderId) => {
    setLoading(true); // Start loading spinner
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.BASE_URL}/order/${orderId}/invoice`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      message.success("Invoice downloaded successfully");
    } catch (error) {
      message.error("Error downloading invoice: " + error.message);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="orderContainer">
      <Title level={2} className="oHeading">
        Your Order List...
      </Title>
      {orders.length > 0 ? (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          dataSource={orders}
          renderItem={(item) => (
            <List.Item>
              <Card
                hoverable
                cover={
                  <Image
                    src={item.colorVariant.images[0]}
                    alt={item.product.name}
                  />
                }
              >
                <Card.Meta
                  title={item.product.name}
                  description={
                    <>
                      <Text>Color: {item.colorVariant.name}</Text>
                      <br />
                      <Text>Price: ₹ {item.price * item.quantity}</Text>
                      <br />
                      <Text>Quantity: {item.quantity}</Text>
                      <br />
                      <Text>Status: {item.status}</Text>
                      <br />
                      <Text>Package ID: {item.packageId}</Text>
                      {item.status === "delivered" && (
                        <Button
                          type="primary"
                          onClick={() => downloadInvoice(item.orderId)}
                          className="invBtn"
                          loading={loading}
                        >
                          Download Invoice
                        </Button>
                      )}
                    </>
                  }
                />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <div className="emptyOrderMessage">No orders found...</div>
      )}
      {loading && (
        <div className="spinner-overlay">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default authGuard(Order);
