import React from "react";
import "./Footer.css";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerContainer">
                <div className="kjarName">KJAR</div>
                <div className="glassFooter">
                    <Link to={`/`} className="tag">
                        <span className="tag">EYEGLASSES</span>
                    </Link>
                    <Link to={`/man-glasses`} className="tag">
                        <span>MEN'S COLLECTION</span>
                    </Link>
                    <Link to={`/women-glasses`} className="tag">
                        <span>WOMEN'S COLLECTION</span>
                    </Link>
                </div>
                <div className="glassFooter">
                <Link to={`/privacypolicy`} className="tag">
                        <span>Privacy Policy</span>
                    </Link>
                    <Link to={`/termsandconditions`} className="tag">
                        <span>Terms and Conditions</span>
                    </Link>
                    <Link to={`/returnandrefund`} className="tag">
                        <span>Return and Refund</span>
                    </Link>
                </div>
                <div className="glassFooter">
                    {/* <span className="tag">About Us</span> */}
                    <Link to={`/contact`} className="tag">
                        <span>Contact Us</span>
                    </Link>
                </div>
            </div>
            <div className="horizontalLine"></div>
            <div className="copyRight">Copyright © 2024, All Rights Reserved</div>
        </div>
    );
};

export default Footer;
