import React, { useEffect, useMemo, useState } from "react";
import "./Cart.css";
import axios from "axios";
import { useSelector } from "react-redux";
import OrderDetails from "../Order/OrderDetails";
import config from "../../config";
import authGuard from "../../guards/auth.guard";

const Cart = () => {
  const [cartItemsWithDetails, setCartItemsWithDetails] = useState([]);
  const userId = useSelector((state) => state.user?.user?.id);
  const [orderDetail, setOrderDetail] = useState(false);

  const shippingCost = 40;
  const itemsPrice = useMemo(
    () =>
      cartItemsWithDetails.reduce(
        (amount, cartItem) =>
          amount + cartItem.colorVariant.price * cartItem.quantity,
        0
      ),
    [cartItemsWithDetails]
  );
  const totalPrice = shippingCost + itemsPrice;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Auth token not available");
        }
        const response = await axios.get(`${config.BASE_URL}/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItemsWithDetails(response.data);
      } catch (error) {
        console.error("Error fetching cart data:", error.message);
      }
    };

    if (userId) {
      fetchCartData();
    }
  }, [userId]);

  const updateCartItem = async (productId, newQuantity) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Auth token not available");
      }
      await axios.put(
        `${config.BASE_URL}/cart/update/${productId}`,
        {
          quantity: newQuantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating cart item quantity:", error.message);
    }
  };

  const handleRemoveItem = async (cartItemId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Auth token not available");
      }
      await axios.delete(`${config.BASE_URL}/cart/remove/${cartItemId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedCartItems = cartItemsWithDetails.filter(
        (item) => item.id !== cartItemId
      );
      setCartItemsWithDetails(updatedCartItems);
    } catch (error) {
      console.error(
        "Error removing item from cart and database:",
        error.message
      );
    }
  };

  const incrementQuantity = async (item) => {
    const newQuantity = item.quantity + 1;
    const updatedCartItems = cartItemsWithDetails.map((cartItem) =>
      cartItem.id === item.id
        ? { ...cartItem, quantity: newQuantity }
        : cartItem
    );
    setCartItemsWithDetails(updatedCartItems);
    await updateCartItem(item.id, newQuantity);
  };

  const decrementQuantity = async (item) => {
    const newQuantity = item.quantity - 1;
    if (newQuantity === 0) {
      await handleRemoveItem(item.id);
    } else {
      const updatedCartItems = cartItemsWithDetails.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: newQuantity }
          : cartItem
      );
      setCartItemsWithDetails(updatedCartItems);
      await updateCartItem(item.id, newQuantity);
    }
  };

  const orderDetailhandleClose = () => {
    setOrderDetail(false);
  };

  const orderDetailhandleOpen = () => {
    setOrderDetail(true);
  };

  return (
    <div className="cartContainer">
      <div className="cItems">
        <div className="myCart">Your Shopping Cart</div>
        <div className="leftCon">
          {cartItemsWithDetails.length > 0 ? (
            cartItemsWithDetails.map((cart) => (
              <div key={cart.id} className="cartItem">
                <div className="itemImg">
                  <img
                    src={cart.colorVariant.images[0]}
                    alt={cart.product.name}
                  />
                </div>
                <div className="pName">{cart.product.name}</div>
                <div className="pColor">{cart.colorVariant.name}</div>
                <div className="quantityControls">
                  <button onClick={() => decrementQuantity(cart)}> -</button>
                  <span className="quantity">{cart.quantity}</span>
                  <button
                    onClick={() => incrementQuantity(cart)}
                    disabled={cart.quantity >= cart.colorVariant.stock}
                  >
                    {" "}
                    +
                  </button>
                </div>
                <div className="pPrice">
                  <span>₹ {cart.colorVariant.price * cart.quantity}/-</span>
                </div>
                <div className="removeItem">
                  <button onClick={() => handleRemoveItem(cart.id)}>
                    Remove
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="emptyCartMessage">Your cart is empty...</div>
          )}
        </div>
      </div>
      {cartItemsWithDetails.length > 0 && (
        <div className="summaryCon">
          <div className="pSummary">Product Summary</div>
          <div className="pLine"></div>
          <div className="pdetail">
            <span>Subtotal</span>
            <span>₹ {itemsPrice.toFixed(2)}</span>
          </div>
          <div className="pdetail">
            <span>Shipping</span>
            <span>₹ {shippingCost.toFixed(2)}</span>
          </div>
          <div className="pdetail">
            <span>Total (Incl. taxes)</span>
            <span>₹ {totalPrice.toFixed(2)}</span>
          </div>
          <div className="pLine"></div>
          <div className="submitBt">
            <button onClick={orderDetailhandleOpen} className="submitButton">
              Checkout
            </button>
          </div>
        </div>
      )}
      {orderDetail && (
        <OrderDetails
          cartItems={cartItemsWithDetails}
          isOpen={orderDetail}
          onClose={orderDetailhandleClose}
        />
      )}
    </div>
  );
};

export default authGuard(Cart);
