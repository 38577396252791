import React, {useEffect} from "react";
import "./Error404.css";

const Error404 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className="errorPage">
            <h1>Oops!!! Page Not Found</h1>
        </div>
    );
};

export default Error404;
